import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { generateGradient } from "@/lib/generate-gradient"
import React from "react"
import { Link } from "react-router"

export type ChatBotCardProps = {
  title: string
  description: string
  link: string
}

export function ChatBotCard({ title, description, link }: ChatBotCardProps) {
  const gradientStyle = React.useMemo(
    () => ({
      background: generateGradient(),
    }),
    [],
  )

  return (
    <Card
      className="w-72 overflow-hidden border-0 p-4 transition-all hover:shadow-lg"
      style={gradientStyle}
    >
      <CardHeader>
        <CardTitle className="truncate">{title}</CardTitle>
      </CardHeader>
      <CardContent className="h-28">
        <p className="line-clamp-4 text-sm text-gray-600 dark:text-gray-300">{description}</p>
      </CardContent>
      <CardFooter>
        <Button asChild={true} size="sm" className="w-full">
          <Link to={link}>Chat Now</Link>
        </Button>
      </CardFooter>
    </Card>
  )
}
