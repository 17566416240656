import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTable } from "@/components/datatable.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc"
import { ColumnDef } from "@tanstack/react-table"
import { CheckIcon } from "lucide-react"
import { Link, useLoaderData, useNavigate } from "react-router"

export async function chatBotListPageLoader() {
  authenticateGuard(["admin"])
  const chatBots = await trpc.chatbots.list.query()
  return { chatBots }
}

type LoaderData = Awaited<ReturnType<typeof chatBotListPageLoader>>

type Record = LoaderData["chatBots"][number]

export const columns: ColumnDef<Record>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "internal",
    header: "Internal",
    cell: ({ getValue }) => {
      const value = getValue() as boolean
      return value ? <CheckIcon /> : null
    },
  },
  {
    accessorKey: "timeUpdated",
    header: "Updated",
    cell: ({ getValue }) => {
      const value = getValue() as string
      return <span className="whitespace-nowrap"> {value.split(" ")[0]}</span>
    },
  },
]

export function ChatBotListPage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "ChatBots",
            href: "/chatbots",
          },
          {
            title: "Manage",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Manage ChatBots</H1>
        <Lead className="mb-8 text-balance">
          Manage your chat bots here. If your chat Chat Bot needs access to documents please ensure
          that you have configured "Knowledge" within the{" "}
          <Link to="/admin/knowledge">Knowledge management</Link> section.
        </Lead>
        <div className="mb-4 flex justify-end">
          <Button variant="default" size="sm" onClick={() => navigate("/chatbots/manage/create")}>
            Create ChatBot
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={data.chatBots}
          onRowClicked={(row: Record) => {
            navigate(`/chatbots/manage/edit/${row.slug ?? row.id}`)
          }}
        />
      </Main>
    </>
  )
}
