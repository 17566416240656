import { cn } from "src/lib/tw-utils"

export function H1({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <h1 className={cn("scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl", className)}>
      {children}
    </h1>
  )
}

export function H2({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <h2
      className={cn(
        "scroll-m-20 border-b pb-2 text-xl font-semibold tracking-tight first:mt-0",
        className,
      )}
    >
      {children}
    </h2>
  )
}

export function H3({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <h3 className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)}>
      {children}
    </h3>
  )
}

export function H4({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <h4 className={cn("scroll-m-20 text-xl font-semibold tracking-tight", className)}>
      {children}
    </h4>
  )
}

export function P({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <p className={cn("text-sm lg:text-base [&:not(:first-child)]:mt-6", className)}>{children}</p>
  )
}

export function Blockquote({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <blockquote className={cn("mt-6 border-l-2 pl-6 italic", className)}>{children}</blockquote>
  )
}

export function Ul({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <ul className={cn("my-6 ml-6 list-disc text-sm lg:text-base [&>li]:mt-2", className)}>
      {children}
    </ul>
  )
}

export function Ol({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <ol className={cn("my-6 ml-6 list-decimal text-sm lg:text-base [&>li]:mt-2", className)}>
      {children}
    </ol>
  )
}

export function InlineCode({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <code
      className={cn(
        "bg-muted relative rounded px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
        className,
      )}
    >
      {children}
    </code>
  )
}

export function Lead({ children, className }: { children: React.ReactNode; className?: string }) {
  return <p className={cn("text-muted-foreground text-sm lg:text-base", className)}>{children}</p>
}

export function Large({ children, className }: { children: React.ReactNode; className?: string }) {
  return <div className={cn("text-lg font-semibold", className)}>{children}</div>
}

export function Small({ children, className }: { children: React.ReactNode; className?: string }) {
  return <small className={cn("text-sm font-medium leading-none", className)}>{children}</small>
}

export function Muted({ children, className }: { children: React.ReactNode; className?: string }) {
  return <p className={cn("text-muted-foreground text-sm", className)}>{children}</p>
}
