import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTable } from "@/components/datatable.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Input } from "@/components/ui/input.tsx"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard, authHeaders } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc"
import { cn } from "@/lib/tw-utils"
import { parseQueryParams } from "@/lib/urls"
import { ColumnDef } from "@tanstack/react-table"
import { VenetianMaskIcon } from "lucide-react"
import React from "react"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router"
import { z } from "zod"

const queryParamsSchema = z.object({
  skip: z.coerce.number().catch(0),
  limit: z.coerce.number().catch(15),
  filter: z.string(),
})

export async function usersListPageLoader(_args: LoaderFunctionArgs) {
  const account = authenticateGuard(["admin", "superadmin"])

  const queryParams = parseQueryParams(_args.request.url, queryParamsSchema)
  const skip = queryParams?.skip ?? 0
  const limit = queryParams?.limit ?? 15
  const filter = queryParams?.filter ?? ""
  const page = await trpc.users.page.query({ skip, limit, filter })

  return { account, page, skip, limit, filter }
}

type LoaderData = Awaited<ReturnType<typeof usersListPageLoader>>

type User = LoaderData["page"]["rows"][number]

export function UsersListPage() {
  const data = useLoaderData() as LoaderData
  const [filter, setFilter] = React.useState(data.filter)
  const navigate = useNavigate()

  const columns = React.useMemo((): ColumnDef<User>[] => {
    const columns: ColumnDef<User>[] = [
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "role",
        header: "Role",
        cell: ({ row }) => {
          const record = row.original
          if (record.role === "admin") {
            return "Admin"
          }
          return "User"
        },
      },
      {
        accessorKey: "timeCreated",
        header: "First Login",
        cell: ({ row }) => {
          const record = row.original
          return record.timeCreated.split(" ")[0]!
        },
      },
      {
        accessorKey: "lastLogin",
        header: "Last Login",
        cell: ({ row }) => {
          const record = row.original
          return (record.lastLogin ?? record.timeCreated).split(" ")[0]!
        },
      },
      {
        accessorKey: "loginCount",
        header: "Login Count",
      },
    ]
    if (data.account.role === "superadmin") {
      columns.push({
        id: "imp",
        cell: ({ row }) => {
          const record = row.original
          if (record.id == data.account.id) {
            return null
          }
          return (
            <Button
              variant="ghost"
              onClick={() => {
                console.log("im")
              }}
            >
              <VenetianMaskIcon />
            </Button>
          )
        },
      })
    }
    return columns
  }, [data.account])

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Users",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Users</H1>
        <Lead className="mb-8 text-balance">
          Manage user access to NetLife AI. It's important to remember that anyone can sign up to
          NetLife AI, but only users with the correct permissions can access chatbots and other
          features.
        </Lead>
        <div className="flex justify-end gap-3">
          <Input
            placeholder="Search term"
            className="max-w-48"
            onChange={(e) => {
              setFilter(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                navigate(
                  `/users?skip=${data.skip}&limit=${data.limit}&filter=${encodeURIComponent(
                    filter,
                  )}`,
                )
              }
            }}
          />
          <Button
            variant="default"
            onClick={() => {
              navigate(
                `/users?skip=${data.skip}&limit=${data.limit}&filter=${encodeURIComponent(filter)}`,
              )
            }}
          >
            Filter
          </Button>
          <Button
            variant="default"
            disabled={!data.filter}
            onClick={() => {
              navigate(`/users?skip=${data.skip}&limit=${data.limit}&filter=`)
            }}
          >
            Clear
          </Button>
          <Button
            variant="default"
            disabled={data.page.rows.length === 0}
            onClick={() => {
              async function downloadReport() {
                const response = await fetch(
                  `${import.meta.env.VITE_API_URL}/reports/users?filter=${encodeURIComponent(data.filter)}`,
                  {
                    headers: {
                      ...authHeaders(),
                    },
                  },
                )
                const blob = await response.blob()
                const downloadUrl = window.URL.createObjectURL(blob)
                const a = document.createElement("a")
                a.href = downloadUrl
                a.download = "netlifeai-users-report.csv"
                a.click()
                window.URL.revokeObjectURL(downloadUrl)
              }
              downloadReport()
            }}
          >
            Download
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={data.page.rows}
          onRowClicked={(record) => {
            navigate(`/users/${record.id}`)
          }}
        />
        {(data.page.hasPrev || data.page.hasNext) && (
          <Pagination className="mt-4">
            <PaginationContent className="w-full justify-between">
              <PaginationItem>
                <PaginationPrevious
                  className={cn("cursor-pointer", !data.page.hasPrev && "opacity-10")}
                  onClick={() => {
                    if (!data.page.hasPrev) {
                      return
                    }
                    navigate(previousPageUrl(data))
                  }}
                />
              </PaginationItem>
              <PaginationItem className="text-muted-foreground text-sm"></PaginationItem>
              <PaginationItem>
                <PaginationNext
                  className={cn("cursor-pointer", !data.page.hasNext && "opacity-10")}
                  onClick={() => {
                    if (!data.page.hasNext) {
                      return
                    }
                    navigate(nextPageUrl(data))
                  }}
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        )}
      </Main>
    </>
  )
}

function previousPageUrl(current: {
  skip: number
  limit: number
  filter?: string
  page: {
    hasPrev: boolean
    hasNext: boolean
  }
}) {
  if (!current.page.hasPrev) {
    return `/admin/users?skip=0&limit=${current.limit}&filter=${current.filter}`
  }
  const skip = current.skip - current.limit >= 0 ? current.skip - current.limit : 0
  return `/admin/users?skip=${skip}&limit=${current.limit}&filter=${current.filter}`
}

function nextPageUrl(current: {
  skip: number
  limit: number
  filter?: string
  page: {
    hasPrev: boolean
    hasNext: boolean
  }
}) {
  if (!current.page.hasNext) {
    return `/admin/users?skip=${current.skip}&limit=${current.limit}&filter=${current.filter}`
  }
  const skip = current.skip + current.limit
  return `/admin/users?skip=${skip}&limit=${current.limit}&filter=${current.filter}`
}
