import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTableV2 } from "@/components/datatable-v2"
import { DocumentThumbnailViewer } from "@/components/document-thumbnail-viewer"
import { Main } from "@/components/layout.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { QueryKeys } from "@/lib/query-keys"
import { RouterOutput, trpc } from "@/lib/trpc-v2"
import { cn } from "@/lib/tw-utils"
import { useQuery } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import React from "react"
import { LoaderFunctionArgs, useLoaderData } from "react-router"

export async function loader({ params }: LoaderFunctionArgs) {
  authenticateGuard(["user"])
  const documentId = params["documentId"] as string
  if (!documentId) {
    throw new Error("documentId path paramater is required")
  }
  return { documentId }
}

type LoaderData = Awaited<ReturnType<typeof loader>>

type DocumentPage = NonNullable<
  RouterOutput["precisionfetch"]["getDocumentDetail"]
>["pages"][number]

function ContentOrSkeleton({
  children,
  className,
  skeletonClassName,
}: {
  children: React.ReactNode
  className?: string
  skeletonClassName?: string
}) {
  return (
    <div className={cn("text-muted-foreground h-4 text-sm", className)}>
      {!children ? (
        <Skeleton className={cn("h-full w-full max-w-[200px]", skeletonClassName)} />
      ) : (
        children
      )}
    </div>
  )
}

export function Page() {
  const { documentId } = useLoaderData() as LoaderData

  const { isPending, data } = useQuery({
    queryKey: QueryKeys.precisionFetch.documentItem(documentId),
    queryFn: async () => {
      const data = await trpc.precisionfetch.getDocumentDetail.query({ id: documentId })
      if (!data) {
        throw new Error("Document not found")
      }
      return data
    },
    refetchInterval: 1000 * 10,
  })

  const columns: ColumnDef<DocumentPage>[] = React.useMemo(
    () => [
      {
        accessorKey: "pageNumber",
        header: "Page",
      },
      {
        id: "image",
        header: "",
        cell: ({ row }) => {
          const record = row.original as DocumentPage
          return <DocumentThumbnailViewer {...record} />
        },
      },
      {
        accessorKey: "context",
        header: "Summary",
        cell: ({ row }) => {
          const record = row.original as DocumentPage
          return <div className="max-w-72 text-sm">{record.context}</div>
        },
      },
      {
        accessorKey: "markdown",
        header: "Content",
        cell: ({ row }) => {
          const record = row.original as DocumentPage
          return (
            <div className="font-mono text-sm">
              <code>
                <pre className="max-h-72 max-w-xl overflow-auto whitespace-pre-wrap">
                  {record.markdown}
                </pre>
              </code>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Precision Fetch",
            href: "/precision-fetch/library",
          },
          {
            title: "Document",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Precision Fetch Document</H1>
        <Lead className="mb-8 text-balance">
          Below are the contents extracted from the document.
        </Lead>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Document</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <div>
                <div className="font-medium">Title</div>
                <ContentOrSkeleton>{data ? (data.document.title ?? "-") : null}</ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Category</div>
                <ContentOrSkeleton>
                  {data ? (data.document.category ?? "-") : null}
                </ContentOrSkeleton>
              </div>
              <div className="col-span-2 h-auto min-h-8">
                <div className="font-medium">Description</div>
                <ContentOrSkeleton className="h-8 w-full">
                  {data ? (data.document.description ?? "-") : null}
                </ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">OEM</div>
                <ContentOrSkeleton>{data ? data.document.oem : null}</ContentOrSkeleton>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Document Pages</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            {data && data.pages && (
              <DataTableV2 columns={columns} data={data.pages} isLoading={isPending} />
            )}
          </CardContent>
        </Card>
      </Main>
    </>
  )
}
