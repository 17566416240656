import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog"

export function DocumentThumbnailViewer(props: {
  smlThumbUrl?: string | null
  smlThumbWidth?: number | null
  smlThumbHeight?: number | null
  lrgThumbUrl?: string | null
  lrgThumbWidth?: number | null
  lrgThumbHeight?: number | null
  pageNumber?: number | null
}) {
  return (
    props.smlThumbUrl &&
    props.lrgThumbUrl && (
      <div className="flex min-w-48 flex-row items-center gap-x-2 text-sm">
        <Dialog>
          <DialogTrigger asChild>
            <button type="button">
              <img
                src={props.smlThumbUrl}
                style={{
                  width: props.smlThumbWidth ?? undefined,
                  height: props.smlThumbHeight ?? undefined,
                }}
                alt="Small Thumbnail"
                className="bg-foreground/80 max-w-80"
              />
            </button>
          </DialogTrigger>
          <DialogContent className="max-w-screen h-screen max-h-[calc(100dvh)] overflow-auto">
            <DialogHeader>
              <DialogTitle>Page {props.pageNumber ?? null}</DialogTitle>
            </DialogHeader>
            <div className="relative flex h-full w-full flex-row items-center justify-center gap-x-2 text-sm">
              <div className="absolute left-0 top-0 flex h-full w-full flex-row items-center justify-center gap-x-2 text-sm">
                Loading...
              </div>
              <img
                src={props.lrgThumbUrl}
                alt="Large Thumbnail"
                width={props.lrgThumbWidth ?? undefined}
                className="bg-foreground/80 h-auto max-h-[calc(90dvh)] max-w-3xl"
              />
            </div>
          </DialogContent>
        </Dialog>
      </div>
    )
  )
}
