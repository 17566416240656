import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { ChatBotForm } from "@/components/chatbot-form.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1 } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc"
import { Loader2 } from "lucide-react"
import { useLoaderData, useNavigate } from "react-router"

export async function chatBotCreatePageLoader() {
  authenticateGuard(["admin"])
  const knowledge = await trpc.knowledge.list.query()
  return { knowledge }
}
type LoaderData = Awaited<ReturnType<typeof chatBotCreatePageLoader>>

export function ChatBotCreatePage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "ChatBots",
            href: "/chatbots",
          },
          {
            title: "Manage",
            href: "/chatbots/manage",
          },
          {
            title: "Create",
          },
        ]}
      />
      <Main>
        <H1 className="mb-8">Create ChatBot</H1>
        <ChatBotForm
          knowledge={data.knowledge}
          onSubmit={async (values) => {
            await trpc.chatbots.create.mutate({
              name: values.name,
              description: values.description,
              model: values.model ?? "gpt-4o",
              internal: values.internal,
              role: values.role,
              context: values.context,
              rules: values.rules,
              openingMessage: values.openingMessage,
              returnMessage: values.returnMessage,
              emailWhitelist: values.emailWhitelist,
              userMessageHandling: values.userMessageHandling,
              knowledgeIds: values.knowledge.map((k) => k.id),
              slug: values.slug,
              assetIds: values.assets.map((a) => a.id),
              capabilities: {
                datalake: values.datalake,
                contextPanel: values.contextPanel,
                chatHistory: values.chatHistory,
                chatThreads: values.chatThreads,
                fileUploads: values.fileUploads,
              },
            })
            // Chatbots show up in nav, so force navigate
            window.location.href = "/chatbots/manage"
          }}
          actions={(state) => (
            <div className="flex flex-grow justify-between">
              <div className="flex gap-3">
                <Button
                  type="submit"
                  variant="default"
                  disabled={state.isSubmitting || state.isLoading || state.isUploading}
                >
                  {(state.isSubmitting || state.isLoading || state.isUploading) && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  )}
                  Create ChatBot
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={state.isSubmitting || state.isLoading || state.isUploading}
                  onClick={() => navigate("/chatbots/manage")}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        />
      </Main>
    </>
  )
}
