import { formatDistanceToNow } from "date-fns"

interface TimeAgoProps {
  date: string
}

export function TimeAgo({ date }: TimeAgoProps) {
  try {
    const parsedDate = new Date(date)
    const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true })
    return <span>{timeAgo}</span>
  } catch (error) {
    console.error("Error formatting date:", error)
    return <span>Invalid date</span>
  }
}
