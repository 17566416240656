import { AppLayout } from "./layouts/app-layout.tsx"
import { AuthLayout } from "./layouts/auth-layout.tsx"
import { ErrorLayout } from "./layouts/error-layout.tsx"
import * as ChatBotChatPage from "./pages/app/chatbot-chat-page.tsx"
import * as ChatBotKnowledgeCreatePage from "./pages/app/chatbot-knowledge-create-page.tsx"
import * as ChatBotKnowledgeEditPage from "./pages/app/chatbot-knowledge-edit-page.tsx"
import * as ChatBotKnowledgeListPage from "./pages/app/chatbot-knowledge-list-page.tsx"
import * as ChatBotListPage from "./pages/app/chatbot-list-page.tsx"
import * as ChatBotManageCreatePage from "./pages/app/chatbot-manage-create-page.tsx"
import * as ChatBotManageEditPage from "./pages/app/chatbot-manage-edit-page.tsx"
import * as ChatBotManageListPage from "./pages/app/chatbot-manage-list-page.tsx"
import * as DocsReleaseNotesPage from "./pages/app/docs-release-notes-page.tsx"
import * as PrecisionFetchDocument from "./pages/app/precision-fetch-backlog-document-page.tsx"
import * as PrecisionFetch from "./pages/app/precision-fetch-backlog-page.tsx"
import * as LibraryDocumentPage from "./pages/app/precision-fetch-library-document-page.tsx"
import * as LibraryPage from "./pages/app/precision-fetch-library-page.tsx"
import * as UserEditPage from "./pages/app/user-edit-page.tsx"
import * as UserListPage from "./pages/app/user-list-page.tsx"
import { AuthCallbackPage } from "./pages/auth/auth-callback-page.tsx"
import { AuthCodePage } from "./pages/auth/auth-code-page.tsx"
import { AuthLoginPage } from "./pages/auth/auth-login-page.tsx"
import { CatchAllPage, catchAllPageLoader } from "./pages/catch-all-page.tsx"
import { ErrorPage } from "./pages/error-page.tsx"
import { Error403Page } from "./pages/errors/403.tsx"
import { Error500Page } from "./pages/errors/500.tsx"
import { PrivacyPage } from "./pages/public/privacy-page.tsx"
import { TermsOfServicePage } from "./pages/public/terms-page.tsx"

export const routes = [
  // PUBLIC ROUTES
  {
    // NOTE: This route has been lifted out as we don't want to use the same layout as other auth pages
    path: "auth/callback",
    element: <AuthCallbackPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "auth",
    element: <AuthLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <AuthLoginPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "code",
        element: <AuthCodePage />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  // "PUBLIC" ROUTES
  {
    path: "errors",
    element: <ErrorLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "403",
        element: <Error403Page />,
        errorElement: <ErrorPage />,
      },
      {
        path: "500",
        element: <Error500Page />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "privacy-policy",
    element: <PrivacyPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "terms-of-service",
    element: <TermsOfServicePage />,
    errorElement: <ErrorPage />,
  },
  // APP ROUTES
  {
    path: "/",
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ChatBotListPage.ChatBotListPage />,
        loader: ChatBotListPage.chatbotListLoader,
        errorElement: <ErrorPage />,
      },
      {
        path: "chatbots",
        children: [
          {
            index: true,
            element: <ChatBotListPage.ChatBotListPage />,
            loader: ChatBotListPage.chatbotListLoader,
            errorElement: <ErrorPage />,
          },
          {
            path: "chat",
            // TODO: Add a "catch-all" route that checks to see if it is a slug or a 404
            children: [
              {
                path: ":chatBotId/:conversationId",
                element: <ChatBotChatPage.ChatBotPage />,
                loader: ChatBotChatPage.chatBotPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: ":chatBotId",
                element: <ChatBotChatPage.ChatBotPage />,
                loader: ChatBotChatPage.chatBotPageLoader,
                errorElement: <ErrorPage />,
              },
            ],
          },
          {
            path: "manage",
            children: [
              {
                index: true,
                element: <ChatBotManageListPage.ChatBotListPage />,
                loader: ChatBotManageListPage.chatBotListPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: "create",
                errorElement: <ErrorPage />,
                element: <ChatBotManageCreatePage.ChatBotCreatePage />,
                loader: ChatBotManageCreatePage.chatBotCreatePageLoader,
              },
              {
                path: "edit/:chatBotId",
                errorElement: <ErrorPage />,
                element: <ChatBotManageEditPage.ChatBotEditPage />,
                loader: ChatBotManageEditPage.chatBotEditPageLoader,
              },
            ],
          },
          {
            path: "knowledge",
            children: [
              {
                index: true,
                element: <ChatBotKnowledgeListPage.KnowledgeListPage />,
                loader: ChatBotKnowledgeListPage.knowledgeListPageLoader,
                errorElement: <ErrorPage />,
              },
              {
                path: "create",
                errorElement: <ErrorPage />,
                element: <ChatBotKnowledgeCreatePage.KnowledgeCreatePage />,
                loader: ChatBotKnowledgeCreatePage.knowledgeCreatePageLoader,
              },
              {
                path: ":knowledgeId",
                errorElement: <ErrorPage />,
                element: <ChatBotKnowledgeEditPage.KnowledgeEditPage />,
                loader: ChatBotKnowledgeEditPage.knowledgeEditPageLoader,
              },
            ],
          },
        ],
      },
      {
        path: "precision-fetch",
        children: [
          {
            path: "library",
            children: [
              {
                index: true,
                errorElement: <ErrorPage />,
                element: <LibraryPage.Page />,
                loader: LibraryPage.loader,
              },
              {
                path: ":documentId",
                errorElement: <ErrorPage />,
                element: <LibraryDocumentPage.Page />,
                loader: LibraryDocumentPage.loader,
              },
            ],
          },
          {
            path: "backlog",
            children: [
              {
                index: true,
                errorElement: <ErrorPage />,
                element: <PrecisionFetch.Page />,
                loader: PrecisionFetch.loader,
              },
              {
                path: ":backlogId",
                errorElement: <ErrorPage />,
                element: <PrecisionFetchDocument.Page />,
                loader: PrecisionFetchDocument.loader,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <UserListPage.UsersListPage />,
            loader: UserListPage.usersListPageLoader,
            errorElement: <ErrorPage />,
          },
          {
            path: ":userId",
            errorElement: <ErrorPage />,
            element: <UserEditPage.UserEditPage />,
            loader: UserEditPage.userEditPageLoader,
          },
        ],
      },
      {
        path: "docs",
        children: [
          {
            path: "release-notes",
            element: <DocsReleaseNotesPage.ReleaseNotesPage />,
          },
        ],
      },
    ],
  },
  // CATCH ALL ROUTE
  // Handles custom "slug" routes as well as 404s
  {
    path: ":slug",
    loader: catchAllPageLoader,
    element: <CatchAllPage />,
  },
]

export const ROOT_ROUTES = ["admin", "auth", "errors", "privacy", "terms", "chatbots", "chat"]
