import type { ControllerRenderProps } from "react-hook-form"

import {
  FormControl,
  FormDescription,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx"
import { Textarea, type TextareaProps } from "@/components/ui/textarea.tsx"

export type TextareaFieldProps = {
  field: ControllerRenderProps<any, any>
  description?: string
  label?: string
} & TextareaProps

export function TextareaField(props: TextareaFieldProps) {
  const { label, field, ...inputProps } = props

  return (
    <FormItem>
      {props.label && <FormLabel htmlFor={props.name}>{props.label}</FormLabel>}
      {inputProps.description && <FormDescription>{inputProps.description}</FormDescription>}
      <FormControl>
        <Textarea {...field} {...inputProps} />
      </FormControl>
      <FormMessage />
    </FormItem>
  )
}
