export namespace QueryKeys {
  export const precisionFetch = {
    backlogs: (args?: { after?: string | null; before?: string | null; limit: number }) =>
      args ? ["precision-fetch:backlogs", args] : ["precision-fetch:backlogs"],
    backlogItem: (id: string) => [`precision-fetch:backlog`, id],
    documents: (args?: { after?: string | null; before?: string | null; limit: number }) =>
      args ? ["precision-fetch:documents", args] : ["precision-fetch:documents"],
    documentItem: (id: string) => [`precision-fetch:document`, id],
  }
}
