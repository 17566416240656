import { z } from "zod"

export const supported_oems = [
  "Alcatel-Lucent (Nokia Networks)",
  "Aviat Networks",
  "Ceragon Networks",
  "Cisco Corporation",
  "Commscope Inc",
  "Delta Power Company",
  "DragonWave-X",
  "ECI Telecom",
  "Eltek (Delta)",
  "Ericsson",
  "Finisar Corporation",
  "Harris-Stratex (Aviat Networks)",
  "Hewlett-Packard (HP)",
  "Huawei Technologies",
  "Juniper Networks",
  "Kathrein-Werke KG",
  "Marconi Communications (Ericsson)",
  "Nippon Electric Corp (NEC)",
  "Nokia Networks",
  "Radwin Wireless",
  "SIAE Microelettronica",
  "Sun Microsystems (Oracle)",
  "Tellabs (Nokia Networks)",
  "Unspecified OEM",
  "ZTE Corporation",
] as const

export const SupportedOemSchema = z.enum(supported_oems)

export type SupportedOem = (typeof supported_oems)[number]
