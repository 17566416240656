import React from "react"

type StatusBallProps = {
  status: "error" | "pending" | "in_progress" | "invalid" | "successful" | string | null | undefined
}

const colorMap = {
  pending: "bg-yellow-500",
  downloading: "bg-blue-500",
  downloaded: "bg-blue-500",
  verifying: "bg-blue-500",
  converting: "bg-blue-500",
  converted: "bg-blue-500",
  finalizing: "bg-blue-500",
  successful: "bg-green-500",
  invalid: "bg-orange-500",
  duplicate: "bg-orange-500",
  error: "bg-red-500",
}

const StatusBall: React.FC<StatusBallProps> = ({ status }) => {
  const colorClass = colorMap[status as keyof typeof colorMap] || "bg-gray-500"

  return <span className={`inline-block h-4 w-4 rounded-full ${colorClass}`} />
}

export default StatusBall
