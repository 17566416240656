import { DataTable } from "@/components/datatable.tsx"
import { Badge } from "@/components/ui/badge.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Label } from "@/components/ui/label.tsx"
import { Textarea } from "@/components/ui/textarea.tsx"
import { useAppLayoutContext } from "@/layouts/app-layout.tsx"
import { timeAgo } from "@/lib/time-ago"
import { trpc } from "@/lib/trpc"
import { cn } from "@/lib/tw-utils"
import type { ChatBots } from "@ai/core/chatbots"
import { Bot, CornerDownLeft, MessageCirclePlusIcon, OctagonXIcon } from "lucide-react"
import React, { JSX } from "react"
import Markdown from "react-markdown"
import { Link, useNavigate } from "react-router"
import { BeatLoader } from "react-spinners"
import invariant from "tiny-invariant"
import { SimpleFileSelector } from "./simple-file-selector.tsx"
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
} from "./ui/sidebar.tsx"

export type ChatbotProps = {
  className?: string
  chatBot: ChatBots.ChatBot
  hasPrevMessages: boolean
  onLoadPrevMessages: (onPrevMessagesLoaded: () => void) => Promise<void>
  messages: ChatBots.ConversationMessage[]
  waitingForResponse: boolean
  streamingResponse: boolean
  onSendUserPrompt: (userMessage: string) => Promise<void>
  conversations: {
    id: string
    timeCreated: string
    title: string | null
  }[]
  selectedFiles: File[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
  status?: string
}

export function Chatbot(props: ChatbotProps) {
  const appLayoutContext = useAppLayoutContext()
  const prevMessagesCountRef = React.useRef<number>(0)
  const loadingPrevMessagesRef = React.useRef(false)
  const messagesEndRef = React.useRef<HTMLDivElement | null>(null)
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null)

  const [userPrompt, setUserPrompt] = React.useState("")

  const conversationsSidebar = React.useMemo(
    () => <ConversationsSidebar chatBot={props.chatBot} conversations={props.conversations} />,
    [props.chatBot, props.conversations],
  )

  // Inject the conversations sidebar into the app layout
  React.useEffect(() => {
    if (!props.chatBot.capabilities.chatThreads) {
      return
    }
    appLayoutContext.setSidebar(conversationsSidebar)
    return () => {
      appLayoutContext.setSidebar(undefined)
    }
  }, [props.chatBot, appLayoutContext.setSidebar, conversationsSidebar])

  // Whenever messages change, scroll to the bottom
  React.useEffect(() => {
    if (!loadingPrevMessagesRef.current && props.messages?.length) {
      const prevMessagesCount = prevMessagesCountRef.current
      messagesEndRef.current?.scrollIntoView({
        behavior: prevMessagesCount === 0 ? "instant" : "smooth",
      })
      prevMessagesCountRef.current = props.messages.length
    }
  }, [props.messages])

  const onLoadPrevMessages = React.useCallback(async () => {
    if (loadingPrevMessagesRef.current) {
      return
    }

    loadingPrevMessagesRef.current = true

    // we sleep a little to let the user know this is happening
    await new Promise((resolve) => setTimeout(resolve, 300))

    // get the current height so we can try to restore the scroll position
    const container = document.getElementById("root")
    invariant(container)
    const prevScrollHeight = container.scrollHeight

    props.onLoadPrevMessages(() => {
      // After previous messages are loaded, adjust scroll so we remain at the original position
      const newScrollHeight = container.scrollHeight
      // Difference in height caused by newly added messages
      const heightDifference = newScrollHeight - prevScrollHeight
      container.scrollTo({
        top: heightDifference,
        behavior: "auto",
      })
      loadingPrevMessagesRef.current = false
    })
  }, [props.onLoadPrevMessages])

  const onLoadPrevMessagesRef = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    const onLoadPrevMessagesRefCurrent = onLoadPrevMessagesRef.current
    if (props.hasPrevMessages && onLoadPrevMessagesRefCurrent) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry?.isIntersecting) {
          onLoadPrevMessages()
        }
      })
      observer.observe(onLoadPrevMessagesRefCurrent)
      return () => observer.disconnect()
    }
  }, [props.hasPrevMessages, props.messages, onLoadPrevMessages])

  const onSendUserPrompt = () => {
    props.onSendUserPrompt(userPrompt)
    requestAnimationFrame(() => setUserPrompt(""))
  }

  return (
    <>
      <div className={cn("flex h-full flex-col", props.className)}>
        <div
          id="chatbot-messages"
          className="flex flex-grow flex-col gap-4 overflow-auto px-8 pb-12 pt-8"
          ref={scrollContainerRef}
        >
          {props.hasPrevMessages && (
            <div ref={onLoadPrevMessagesRef} className="mb-10 flex animate-pulse justify-around">
              Loading older messages...
            </div>
          )}
          {props.messages.map((message, index) => {
            let msgElement: JSX.Element

            if (message.role === "assistant") {
              msgElement = (
                <AssistantMessage key={index}>
                  {message.parts.map((part, index) => {
                    if (part.type === "text") {
                      return (
                        <div key={index} className="max-w-4xl">
                          <StyledMarkdown>{part.text}</StyledMarkdown>
                        </div>
                      )
                    } else if (part.type === "data") {
                      const data = part.data as any
                      if (data.type === "datatable") {
                        return <DataTableMessagePart key={index} data={data} />
                      }
                    } else {
                      throw new Error(`Invalid part: ${JSON.stringify(part)}`)
                    }
                  })}
                </AssistantMessage>
              )
            } else if (message.role === "user") {
              msgElement = (
                <UserMessage key={index}>
                  {message.parts.map((part, index) => {
                    if (part.type === "text") {
                      return <StyledMarkdown key={index}>{part.text}</StyledMarkdown>
                    } else {
                      throw new Error(`Invalid part: ${JSON.stringify(part)}`)
                    }
                  })}
                </UserMessage>
              )
            } else {
              throw new Error(`Invalid message: ${JSON.stringify(message)}`)
            }
            return msgElement
          })}
          {props.waitingForResponse && !props.streamingResponse && (
            <AssistantMessage className="animate-pulse">
              <BeatLoader color="#1E3A8A" />
            </AssistantMessage>
          )}
          <div ref={messagesEndRef} />
        </div>
        <div className="bg-background sticky bottom-0">
          <div className="flex flex-col px-4 pb-4">
            <div className="flex h-10 text-sm">
              {props.status ? (
                <div className="flex h-full animate-pulse flex-row items-center gap-3 px-2">
                  <BeatLoader color="#1E3A8A" />
                  <span className="text-sm">{props.status}</span>
                </div>
              ) : props.chatBot.capabilities.fileUploads && props.selectedFiles.length > 0 ? (
                <div className="flex h-full flex-row flex-wrap gap-3 px-2">
                  {props.selectedFiles.map((file) => (
                    <div key={file.name} className="flex flex-row items-center gap-1">
                      <div className="ml-2 text-sm">{file.name}</div>
                      <Button
                        size="icon"
                        variant="ghost"
                        onClick={() => {
                          props.setSelectedFiles((current) => current.filter((f) => f !== file))
                        }}
                      >
                        <OctagonXIcon className="size-3.5" />
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex h-full" />
              )}
            </div>
            <form className="bg-background focus-within:ring-ring relative flex-grow overflow-hidden rounded-lg border focus-within:ring-1">
              <Label htmlFor="message" className="sr-only">
                Message
              </Label>
              <div className="display flex h-full flex-row">
                {props.chatBot.capabilities.fileUploads && (
                  <div className="p-3">
                    <SimpleFileSelector
                      disabled={!!props.status || props.waitingForResponse}
                      fileTypes={[
                        ".csv",
                        ".pdf",
                        ".txt",
                        ".doc",
                        ".docx",
                        ".xlsx",
                        ".png",
                        ".gif",
                        ".jpeg",
                        ".jpg",
                      ]}
                      fileSizeLimitInBytes={1024 * 1024 * 10}
                      multiple
                      onFileSelect={(files) => {
                        if (files.length > 0) {
                          props.setSelectedFiles((current) => [...current, ...files])
                        }
                      }}
                    />
                  </div>
                )}
                <Textarea
                  autoFocus
                  id="message"
                  placeholder="Type your message here..."
                  className="h-full resize-none border-0 p-3 shadow-none focus-visible:ring-0"
                  onChange={(event) => setUserPrompt(event.target.value)}
                  onKeyDown={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                      event.preventDefault()
                      if (!!props.status || props.waitingForResponse) {
                        console.log("Not sending message", {
                          status: props.status,
                          waitingForResponse: props.waitingForResponse,
                        })
                        return
                      }
                      onSendUserPrompt()
                    }
                  }}
                  value={userPrompt}
                />
              </div>
              <div className="absolute bottom-1 right-1 h-10">
                <Label htmlFor="send-message" className="sr-only">
                  Send Message
                </Label>
                <Button
                  disabled={!!props.status || props.waitingForResponse}
                  id="send-message"
                  type="button"
                  size="sm"
                  className="h-10 rounded-full"
                  onClick={onSendUserPrompt}
                >
                  <CornerDownLeft className="size-3" />
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

function UserMessage({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div className={cn("relative my-8 flex justify-end", className)}>
      <div className="bg-muted/50 max-w-3xl rounded-3xl px-12 py-4 backdrop-blur-sm backdrop-saturate-200">
        {children}
      </div>
    </div>
  )
}

function AssistantMessage({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div className={cn("relative flex flex-row gap-4", className)}>
      <div>
        <Badge className="text-foreground relative h-8 w-8 font-bold md:h-9 md:w-9">
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
            <Bot className="w-6 md:w-7" />
          </div>
        </Badge>
      </div>
      <div className="max-w-full flex-grow">{children}</div>
    </div>
  )
}

function StyledMarkdown({ children }: { children: string }) {
  return (
    <Markdown
      skipHtml
      components={{
        h1: (props) => (
          <h1 className="mt-8 scroll-m-20 text-xl font-extrabold tracking-tight first:mt-0 lg:text-3xl">
            {props.children}
          </h1>
        ),
        h2: (props) => (
          <h2 className="mt-8 scroll-m-20 border-b pb-2 text-xl font-semibold tracking-tight first:mt-0">
            {props.children}
          </h2>
        ),
        h3: (props) => (
          <h3 className="mt-6 scroll-m-20 text-lg font-semibold tracking-tight first:mt-0">
            {props.children}
          </h3>
        ),
        h4: (props) => (
          <h4 className="mt-6 scroll-m-20 text-lg font-semibold tracking-tight first:mt-0">
            {props.children}
          </h4>
        ),
        p: (props) => (
          <p className="text-sm lg:text-base [&:not(:first-child)]:mt-6">{props.children}</p>
        ),
        ul: (props) => (
          <ul className="my-6 ml-6 list-disc text-sm lg:text-base [&>li]:mt-2">{props.children}</ul>
        ),
        ol: (props) => (
          <ol className="my-6 ml-6 list-decimal text-sm lg:text-base [&>li]:mt-2">
            {props.children}
          </ol>
        ),
        a: (props) => <a {...props} className="text-primary underline" />,
        hr: (props) => <hr {...props} className="border-muted/50 my-6 border-b" />,
      }}
    >
      {children}
    </Markdown>
  )
}

function DataTableMessagePart(props: { data: any }) {
  if (props.data.type === "datatable") {
    const columns = props.data.props.headers.map((header: any, idx: any) => ({
      accessorKey: idx.toString(),
      header,
    }))
    return (
      <>
        <h3 className="mt-8 scroll-m-20 text-lg font-semibold tracking-tight first:mt-0">
          {props.data.props.title}
        </h3>
        <p className="text-sm lg:text-base [&:not(:first-child)]:mt-6">
          {props.data.props.description}
        </p>
        <div className="mb-6 mt-6 max-w-full overflow-auto">
          <DataTable columns={columns} data={props.data.props.rows} />
        </div>
      </>
    )
  }
  throw new Error(`Invalid component config: ${JSON.stringify(props.data)}`)
}

function ConversationsSidebar(props: {
  chatBot: ChatBots.ChatBot
  conversations: {
    id: string
    timeCreated: string
    title: string | null
  }[]
}) {
  const navigate = useNavigate()
  const [creatingConversation, setCreatingConversation] = React.useState(false)
  const onCreateNewConversationClick = async () => {
    setCreatingConversation(true)
    await trpc.chatbots.createConversation.mutate({
      chatBotId: props.chatBot.id,
    })
    navigate(`/chat/${props.chatBot.id}`)
    setCreatingConversation(false)
  }
  return (
    <Sidebar collapsible="none" className="sticky top-0 hidden h-svh border-l lg:flex">
      <SidebarHeader className="border-sidebar-border flex h-16 items-center justify-start border-b p-4">
        <Button
          size="sm"
          variant="ghost"
          onClick={onCreateNewConversationClick}
          disabled={creatingConversation}
        >
          <MessageCirclePlusIcon />
          Start New
        </Button>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup className="px-0">
          <SidebarGroupContent>
            {props.conversations.map((conversation) => (
              <Link
                to={`/chat/${props.chatBot.id}/${conversation.id}`}
                key={conversation.id}
                className="hover:bg-sidebar-accent hover:text-sidebar-accent-foreground flex flex-col items-start gap-2 whitespace-nowrap border-b p-4 text-sm leading-tight last:border-b-0"
              >
                <span className="line-clamp-2 w-full whitespace-break-spaces text-xs">
                  {conversation.title ?? "Untitled conversation"}
                </span>
                <div className="flex w-full items-center gap-2">
                  <span className="ml-auto text-xs">{timeAgo(conversation.timeCreated)}</span>
                </div>
              </Link>
            ))}
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
    </Sidebar>
  )
}
