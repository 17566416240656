import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTableV2 } from "@/components/datatable-v2"
import { DocumentThumbnailViewer } from "@/components/document-thumbnail-viewer"
import { Main } from "@/components/layout.tsx"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Skeleton } from "@/components/ui/skeleton"
import StatusBall from "@/components/ui/status-ball"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { QueryKeys } from "@/lib/query-keys"
import { RouterOutput, trpc } from "@/lib/trpc-v2"
import { cn } from "@/lib/tw-utils"
import { useQuery } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import React from "react"
import { LoaderFunctionArgs, useLoaderData } from "react-router"

export async function loader({ params }: LoaderFunctionArgs) {
  authenticateGuard(["admin"])
  const backlogId = params["backlogId"] as string
  if (!backlogId) {
    throw new Error("Backlog ID is required")
  }
  return { backlogId }
}

type LoaderData = Awaited<ReturnType<typeof loader>>

type Backlog = NonNullable<RouterOutput["precisionfetch"]["getBacklogDetail"]>["backlog"]
type BacklogPage = NonNullable<RouterOutput["precisionfetch"]["getBacklogDetail"]>["pages"][number]

function statusText(status: Backlog["status"]) {
  switch (status) {
    case "paused":
      return "Paused"
    case "pending":
      return "Pending"
    case "downloading":
      return "Downloading"
    case "downloaded":
      return "Downloaded"
    case "verifying":
      return "Verifying"
    case "converting":
      return "Converting"
    case "converted":
      return "Converted"
    case "finalizing":
      return "Finalizing"
    case "successful":
      return "Successful"
    case "duplicate":
      return "Duplicate"
    case "error":
      return "Failed"
    case "invalid":
      return "Rejected"
  }
}

function ContentOrSkeleton({
  children,
  className,
  skeletonClassName,
}: {
  children: React.ReactNode
  className?: string
  skeletonClassName?: string
}) {
  return (
    <div className={cn("text-muted-foreground h-4 text-sm", className)}>
      {!children ? (
        <Skeleton className={cn("h-full w-full max-w-[200px]", skeletonClassName)} />
      ) : (
        children
      )}
    </div>
  )
}

export function Page() {
  const { backlogId } = useLoaderData() as LoaderData

  const { isPending, data } = useQuery({
    queryKey: QueryKeys.precisionFetch.backlogItem(backlogId),
    queryFn: async () => {
      const data = await trpc.precisionfetch.getBacklogDetail.query({ id: backlogId })
      if (!data) {
        throw new Error("Backlog not found")
      }
      return data
    },
    refetchInterval: 1000 * 30,
  })

  const columns: ColumnDef<BacklogPage>[] = React.useMemo(
    () => [
      {
        accessorKey: "status",
        header: "",
        cell: ({ row }) => {
          const record = row.original as BacklogPage
          return <StatusBall status={record.status} />
        },
      },
      {
        accessorKey: "pageNumber",
        header: "Page",
      },
      {
        id: "image",
        header: "",
        cell: ({ row }) => {
          const record = row.original as BacklogPage
          return <DocumentThumbnailViewer {...record} />
        },
      },
      {
        accessorKey: "markdown",
        header: "Content",
        cell: ({ row }) => {
          const record = row.original as BacklogPage
          return (
            <div className="font-mono text-sm">
              <code>
                <pre className="max-h-72 max-w-xl overflow-auto whitespace-pre-wrap">
                  {record.markdown}
                </pre>
              </code>
            </div>
          )
        },
      },
    ],
    [],
  )

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Precision Fetch",
            href: "/precision-fetch/library",
          },
          {
            title: "Backlog",
            href: "/precision-fetch/backlog",
          },
          {
            title: "Document",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Precision Fetch Backlog Document</H1>
        <Lead className="mb-8 text-balance">
          Below is the status of the backlog document being processed by Precision Fetch.
        </Lead>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Target Document</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <div>
                <div className="font-medium">OEM</div>
                <ContentOrSkeleton>{data ? data.backlog.oem : null}</ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Search Term</div>
                <ContentOrSkeleton>{data ? data.backlog.searchTerm : null}</ContentOrSkeleton>
              </div>
              <div className="col-span-2">
                <div className="font-medium">URL</div>
                <ContentOrSkeleton className="break-all">
                  {data ? data.backlog.url : null}
                </ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Domain</div>
                <ContentOrSkeleton>{data ? (data.backlog.domain ?? "-") : null}</ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Filename</div>
                <ContentOrSkeleton>
                  {data ? (data.backlog.filenameInUrl ?? "-") : null}
                </ContentOrSkeleton>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Document Analysis</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <div>
                <div className="font-medium">Title</div>
                <ContentOrSkeleton>{data ? (data.backlog.title ?? "-") : null}</ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Category</div>
                <ContentOrSkeleton>
                  {data ? (data.backlog.category ?? "-") : null}
                </ContentOrSkeleton>
              </div>
              <div className="col-span-2 h-auto min-h-8">
                <div className="font-medium">Description</div>
                <ContentOrSkeleton className="h-8 w-full">
                  {data ? (data.backlog.description ?? "-") : null}
                </ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Official?</div>
                <ContentOrSkeleton>
                  {data
                    ? data.backlog.isOfficial === null
                      ? "-"
                      : data.backlog.isOfficial
                        ? "Yes"
                        : "No"
                    : null}
                </ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Obscured?</div>
                <ContentOrSkeleton>
                  {data
                    ? data.backlog.isObscured === null
                      ? "-"
                      : data.backlog.isObscured
                        ? "Yes"
                        : "No"
                    : null}
                </ContentOrSkeleton>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Status</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            <div className="grid grid-cols-2 gap-x-4 gap-y-6">
              <div>
                <div className="font-medium">Status</div>
                <ContentOrSkeleton>
                  {data ? statusText(data.backlog.status ?? "pending") : null}
                </ContentOrSkeleton>
              </div>
              <div>
                <div className="font-medium">Status Log</div>
                <ContentOrSkeleton>
                  {data ? (data.backlog.statusText ?? "-") : null}
                </ContentOrSkeleton>
              </div>
              <div className="col-span-2">
                <div className="font-medium">Reason</div>
                <ContentOrSkeleton className="h-10 w-full overflow-y-auto">
                  {data ? (data.backlog.reason ?? "-") : null}
                </ContentOrSkeleton>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="mb-8">
          <CardHeader>
            <CardTitle>Document Pages</CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4">
            {data && data.pages && (
              <DataTableV2 columns={columns} data={data.pages} isLoading={isPending} />
            )}
          </CardContent>
        </Card>
      </Main>
    </>
  )
}
