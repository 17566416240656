import { NavSecondary } from "@/components/nav-secondary.tsx"
import { NavUser } from "@/components/nav-user.tsx"
import { Navigation, NavigationItem, NavigationItemWithChildren } from "@/components/navigation"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar.tsx"
import { Session } from "@/context/auth.tsx"
import { BookOpen, Bot, MessageCircleQuestionIcon, TargetIcon, Users } from "lucide-react"
import * as React from "react"
import { Link } from "react-router"

export type AppSidebarProps = {
  session: Session
} & React.ComponentProps<typeof Sidebar>

export function AppSidebar({ session, ...props }: AppSidebarProps) {
  const navigation = React.useMemo(() => {
    const main: NavigationItem[] = []

    const chatBotsMenu: NavigationItemWithChildren = {
      title: "ChatBots",
      icon: Bot,
      url: "/",
      items: [],
    }
    main.push(chatBotsMenu)

    if (session.account.role === "admin" || session.account.role === "superadmin") {
      chatBotsMenu.items.push({
        title: "Manage",
        url: "/chatbots/manage",
      })

      chatBotsMenu.items.push({
        title: "Knowledge",
        url: "/chatbots/knowledge",
      })

      main.push({
        title: "Precision Fetch",
        icon: TargetIcon,
        url: "/precision-fetch/library",
        items: [
          {
            title: "Backlog",
            url: "/precision-fetch/backlog",
          },
        ],
      })
      main.push({
        title: "Users",
        icon: Users,
        url: "/users",
      })
      main.push({
        title: "Documentation",
        icon: BookOpen,
        items: [
          {
            title: "Release Notes",
            url: "/docs/release-notes",
          },
        ],
      })
    }

    return {
      navMain: main,
      navAdministration: [, ,],
      navSecondary: [
        {
          title: "WhatsApp Support",
          url: "https://chat.whatsapp.com/H3WMFwSmXyY3XYVFkuNE0o",
          icon: MessageCircleQuestionIcon,
        },
      ],
      projects: [],
    }
  }, [])
  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <Link to="/">
                <div className="text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg">
                  <img src="/favicon.svg" alt="Logo" className="size-8" />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">NetLife</span>
                  <span className="truncate text-xs">Artificial Intelligence</span>
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <Navigation title="Platform" items={navigation.navMain} />
        <NavSecondary items={navigation.navSecondary} className="mt-auto" />
      </SidebarContent>
      <SidebarFooter>
        <NavUser session={session} />
      </SidebarFooter>
    </Sidebar>
  )
}
