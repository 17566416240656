import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTableV2 } from "@/components/datatable-v2"
import { DocumentThumbnailViewer } from "@/components/document-thumbnail-viewer"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Input } from "@/components/ui/input"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { QueryKeys } from "@/lib/query-keys"
import { RouterOutput, trpc } from "@/lib/trpc-v2"
import { useQuery } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import { EyeIcon } from "lucide-react"
import React from "react"
import { useNavigate } from "react-router"

export async function loader() {
  authenticateGuard(["user"])
}

type DocumentRecord = RouterOutput["precisionfetch"]["listDocuments"]["items"][number]

const PAGE_SIZE = 15

export function Page() {
  const navigate = useNavigate()

  const [paging, setPaging] = React.useState<{
    mode?: "document" | "page"
    search?: string | null
    after?: string | null
    before?: string | null
    limit: number
  }>({
    search: null,
    after: null,
    before: null,
    limit: PAGE_SIZE,
  })

  const [search, setSearch] = React.useState(paging.search ?? "")

  const { isPending, data } = useQuery({
    queryKey: QueryKeys.precisionFetch.documents(paging),
    queryFn: () => trpc.precisionfetch.listDocuments.query(paging),
  })

  const columns: ColumnDef<DocumentRecord>[] = [
    {
      id: "image",
      header: "",
      cell: ({ row }) => {
        const record = row.original as DocumentRecord
        return <DocumentThumbnailViewer {...record} />
      },
    },
    {
      header: "OEM",
      accessorKey: "oem",
    },
    {
      header: "Title",
      accessorKey: "title",
    },
    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Page Count",
      accessorKey: "pageCount",
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        const record = row.original as DocumentRecord
        return (
          <div className="flex flex-row justify-end gap-2">
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={() => navigate(`/precision-fetch/library/${record.id.trim()}`)}
            >
              <EyeIcon />
            </Button>
          </div>
        )
      },
    },
  ]

  const pagination = (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            disabled={!data?.hasBefore}
            onClick={() => {
              setPaging((prev) => ({
                ...prev,
                after: null,
                before: data?.before ?? null,
              }))
            }}
          />
        </PaginationItem>
        <PaginationItem />
        <PaginationItem>
          <PaginationNext
            disabled={!data?.hasAfter}
            onClick={() => {
              setPaging((prev) => ({
                ...prev,
                before: null,
                after: data?.after ?? null,
              }))
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Precision Fetch",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Precision Fetch</H1>
        <Lead className="mb-8 text-balance">
          Below is our current catalog of OEM documents that have been sourced, verified, and
          processed by Precision Fetch.
        </Lead>
        <div className="flex justify-end gap-3">
          <Input
            placeholder="Search term"
            className="max-w-48"
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setPaging({
                  search,
                  after: null,
                  before: null,
                  limit: PAGE_SIZE,
                })
              }
            }}
          />
          <Button
            variant="default"
            onClick={() => {
              setPaging({
                search,
                after: null,
                before: null,
                limit: PAGE_SIZE,
              })
            }}
          >
            Search
          </Button>
          <Button
            variant="default"
            disabled={!search}
            onClick={() => {
              setPaging({
                search: null,
                after: null,
                before: null,
                limit: PAGE_SIZE,
              })
            }}
          >
            Clear
          </Button>
        </div>
        {pagination}
        <DataTableV2
          columns={columns}
          data={data?.items ?? []}
          skeletonRowsCount={PAGE_SIZE}
          isLoading={isPending || !data}
        />
        {pagination}
      </Main>
    </>
  )
}
