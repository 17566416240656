import { RouterOutput } from "@/lib/trpc"
import { ColumnDef } from "@tanstack/react-table"
import React from "react"
import { DataTable } from "./datatable.tsx"
import { Checkbox } from "./ui/checkbox.tsx"

export type Knowledge = Pick<
  RouterOutput["knowledge"]["list"][number],
  "id" | "name" | "description"
>

type Row = Knowledge & {
  selected: boolean
}

export type KnowledgeSelectorProps = {
  initialValue?: Knowledge[]
  options: Knowledge[]
  onChange: (value: Knowledge[]) => void
}

export function KnowledgeSelector(props: KnowledgeSelectorProps) {
  const [rows, setRows] = React.useState<Row[]>(
    props.options.map((i) => ({
      ...i,
      selected: props.initialValue?.some((x) => x.id === i.id) ?? false,
    })),
  )

  const columns: ColumnDef<Row>[] = [
    {
      id: "action",
      header: () => <div className="flex items-center justify-center">Selected?</div>,
      cell: ({ row }) => {
        const record = row.original
        return (
          <div className="flex items-center justify-center">
            <Checkbox
              onCheckedChange={(checked) => {
                setRows((current) => {
                  const next = current.map((i) => {
                    if (i.id === record.id) {
                      return { ...i, selected: !!checked }
                    }
                    return i
                  })

                  props.onChange(next.filter((i) => i.selected).map((i) => i))

                  return next
                })
              }}
              checked={record.selected}
            />
          </div>
        )
      },
    },
    {
      accessorKey: "name",
      header: "Name",
    },
    {
      header: "Description",
      cell: ({ row }) => {
        const record = row.original
        return <div className="flex-grow">{record.description}</div>
      },
    },
  ]

  return <DataTable columns={columns} data={rows} emptyMessage="No Knowledge available" />
}
