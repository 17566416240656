import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTable } from "@/components/datatable.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc"
import { ColumnDef } from "@tanstack/react-table"
import { useLoaderData, useNavigate } from "react-router"

export async function knowledgeListPageLoader() {
  authenticateGuard(["admin"])
  const knowledge = await trpc.knowledge.list.query()
  return { knowledge }
}

type LoaderData = Awaited<ReturnType<typeof knowledgeListPageLoader>>

type Record = LoaderData["knowledge"][number]

export const columns: ColumnDef<Record>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "description",
    header: "Description",
  },
  {
    accessorKey: "timeCreated",
    header: "Created",
    cell: ({ getValue }) => {
      const value = getValue() as string
      return <span className="whitespace-nowrap"> {value.split(" ")[0]}</span>
    },
  },
]

export function KnowledgeListPage() {
  const data = useLoaderData() as LoaderData
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "ChatBots",
            href: "/chatbots/",
          },
          {
            title: "Knowledge",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Knowledge</H1>
        <Lead className="mb-8 text-balance">
          Knowledge are collections of files that can be utilized by ChatBots. They enable us to
          share the same sets of files across multiple ChatBots. Any updates performed against a
          Knowledge will immediately be reflected against respective ChatBots.
        </Lead>
        <div className="mb-4 flex justify-end">
          <Button
            variant="default"
            size="sm"
            onClick={() => navigate("/chatbots/knowledge/create")}
          >
            Create Knowledge
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={data.knowledge}
          onRowClicked={(record) => navigate(`/chatbots/knowledge/${record.id}`)}
        />
      </Main>
    </>
  )
}
