import type { Account } from "@ai/core/actor"
import * as React from "react"
import invariant from "tiny-invariant"

export type Session = {
  account: Account
  token: string
}

type AuthorizationContextType = {
  session: Session | null
  loginUrls: {
    code: (args: { code: string }) => string
    email: (args: { email: string }) => string
  }
  logout: () => void
}

const AuthorizationContext = React.createContext<AuthorizationContextType>(null as any)

export type AuthorizationProps = {
  children: React.ReactNode
}

export function AuthorizationProvider(props: AuthorizationProps) {
  const session = React.useMemo<Session | null>(() => {
    const params = new URLSearchParams(location.hash.substring(1))
    console.log("params", params)
    const token = params.get("access_token")
    if (token) {
      // Handling an auth callback, this should become the authoritative account
      const account = decodeToken({ token })
      console.debug("registering account from callback", account)
      const session: Session = { token, account }
      store.set(session)
      return session
    }
    return store.get()
  }, [])

  return (
    <AuthorizationContext.Provider
      value={{
        session,
        loginUrls,
        logout,
      }}
    >
      {props.children}
    </AuthorizationContext.Provider>
  )
}

export function getAccount() {
  const session = store.get()
  return session?.account
}

export function authenticateGuard(allowedRoles?: ("user" | "admin" | "superadmin")[]) {
  const session = store.get()
  if (!session) {
    throw new Error("Access Denied")
  }

  if (!allowedRoles) {
    return session.account
  }

  const userRole = session.account.role

  const hasAccess =
    userRole === "superadmin" ||
    (userRole === "admin" && allowedRoles.some((r) => ["admin", "user"].includes(r))) ||
    (userRole === "user" && allowedRoles.includes("user"))

  if (!hasAccess) {
    throw new Error("Access Denied")
  }

  return session.account
}

export function useLogout() {
  return useAuth().logout
}

export function useAuthUrls() {
  return useAuth().loginUrls
}

export function authHeaders(): Record<string, string> {
  const session = store.get()
  if (!session) return {}
  return {
    authorization: `Bearer ${session.token}`,
  }
}

function decodeToken(input: { token: string }): Account {
  const [, payloadEncoded] = input.token.split(".")
  invariant(payloadEncoded, "Invalid access token")
  const payload = JSON.parse(atob(payloadEncoded.replace(/-/g, "+").replace(/_/g, "/")))
  return {
    ...payload.properties,
    token: input.token,
  }
}

function loginUrl(args: { params?: Record<string, string> }) {
  const params = new URLSearchParams({
    client_id: "web",
    response_type: "token",
    provider: "email",
    redirect_uri: location.origin + "/auth/callback",
    ...args.params,
  })
  const url = `${import.meta.env.VITE_AUTH_URL}/email/authorize?${params.toString()}`
  return url
}

const loginUrls: AuthorizationContextType["loginUrls"] = {
  email: (args) => loginUrl({ params: { email: args.email } }),
  code: (args: { code: string }) => {
    const params = new URLSearchParams({
      code: args.code,
    })
    const url = `${import.meta.env.VITE_AUTH_URL}/email/callback?${params.toString()}`
    return url
  },
}

const store = {
  get() {
    const raw = localStorage.getItem("session")
    if (!raw) return null
    return JSON.parse(raw) as Session
  },
  set(session: Session) {
    return localStorage.setItem("session", JSON.stringify(session))
  },
  remove() {
    return localStorage.removeItem("session")
  },
}

export function logout() {
  store.remove()
  location.href = location.origin
}

export function useAuth() {
  const result = React.useContext(AuthorizationContext)
  if (!result) throw new Error("useAuth must be used within an AuthProvider")
  return result
}
