import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { KnowledgeForm } from "@/components/knowledge-form.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx"
import { H1 } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router"
import invariant from "tiny-invariant"

export async function knowledgeEditPageLoader({ params }: LoaderFunctionArgs) {
  authenticateGuard(["admin"])
  invariant(params.knowledgeId, "knowledgeId is required")
  const knowledge = await trpc.knowledge.get.query({
    knowledgeId: params.knowledgeId,
  })
  invariant(knowledge, "Knowledge not found")
  return { knowledge }
}

type LoaderData = Awaited<ReturnType<typeof knowledgeEditPageLoader>>

export function KnowledgeEditPage() {
  const data = useLoaderData() as LoaderData

  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "ChatBots",
            href: "/chatbots/",
          },
          {
            title: "Knowledge",
            href: "/chatbots/knowledge",
          },
          {
            title: data.knowledge.name,
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Update Knowledge</H1>

        <KnowledgeForm
          data={data.knowledge}
          onSubmit={async (values) => {
            await trpc.knowledge.update.mutate({
              knowledgeId: data.knowledge.id,
              name: values.name,
              description: values.description,
              assetIds: values.assets.map((f) => f.id),
            })
            navigate("/chatbots/knowledge")
          }}
          actions={(formState) => (
            <>
              <div className="flex flex-grow justify-between">
                <div className="flex gap-3">
                  <Button
                    type="submit"
                    variant="default"
                    disabled={
                      formState.isSubmitting || formState.isLoading || formState.isUploading
                    }
                  >
                    Update Knowledge
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    disabled={
                      formState.isSubmitting || formState.isLoading || formState.isUploading
                    }
                    onClick={() => {
                      navigate("/chatbots/knowledge")
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="flex gap-3">
                  <Dialog>
                    <DialogTrigger asChild={true}>
                      <Button
                        type="button"
                        variant="destructive"
                        disabled={
                          formState.isSubmitting || formState.isLoading || formState.isUploading
                        }
                      >
                        Delete
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogTitle>Confirmation Needed</DialogTitle>
                      <DialogDescription>
                        You have requested that this Knowledge be removed from our system. This
                        action cannot be reversed. Any ChatBot that was using this Knowledge will no
                        longer be able to leverage the data represented by the Knowledge. Please
                        confirm the deletion below, or cancel if you wish to keep the Knowledge.
                      </DialogDescription>
                      <DialogFooter>
                        <Button
                          variant="destructive"
                          onClick={async () => {
                            await trpc.knowledge.remove.mutate({ knowledgeId: data.knowledge.id })
                            navigate("/chatbots/knowledge")
                          }}
                        >
                          Confirm Delete
                        </Button>
                        <DialogClose asChild={true}>
                          <Button>Cancel</Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </>
          )}
        />
      </Main>
    </>
  )
}
