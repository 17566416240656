import { cn } from "@/lib/tw-utils"

export * as PageLayout from "./page-layout.tsx"

export const Shell: React.FC<{
  children: React.ReactNode
  className?: string
}> = (props) => {
  return (
    <div className={cn("mx-auto flex h-full w-full flex-col", props.className)}>
      {props.children}
    </div>
  )
}

export const Main: React.FC<{ children: React.ReactNode; className?: string }> = (props) => {
  return (
    <main className={cn("my-8 flex flex-grow flex-col", props.className)}>{props.children}</main>
  )
}

export const NarrowContent: React.FC<{ children: React.ReactNode }> = (props) => {
  return <div className="mx-auto w-full max-w-2xl">{props.children}</div>
}

export const Section: React.FC<{ children: React.ReactNode }> = (props) => {
  return <section className="my-8">{props.children}</section>
}
