import { cn } from "@/lib/tw-utils"
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table"

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  emptyMessage?: string
  data: TData[]
  disableHeader?: boolean
  isRowClickable?: (row: TData) => boolean
  onRowClicked?: (row: TData) => void
}

export function DataTable<TData, TValue>(props: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  })

  return (
    <table className="min-w-full divide-y divide-gray-300 overflow-hidden rounded-xl">
      {!props.disableHeader && (
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  scope="col"
                  className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  colSpan={header.colSpan}
                  style={{ width: `${header.getSize()}px` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody className="divide-y divide-gray-200 bg-white">
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => {
            const clickable = props.isRowClickable
              ? props.isRowClickable(row.original) && props.onRowClicked
              : props.onRowClicked != null
            return (
              <tr
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                onClick={() => {
                  if (clickable && props.onRowClicked) {
                    props.onRowClicked(row.original)
                  }
                }}
                className={cn(
                  clickable &&
                    "hover:bg-muted/10 hover:text-primary cursor-pointer transition-colors",
                )}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6"
                    style={{ width: `${cell.column.getSize()}px` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            )
          })
        ) : (
          <tr>
            <td
              colSpan={props.columns.length}
              className="text-background px-4 py-3 text-center text-sm"
            >
              {props.emptyMessage ?? "No results."}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
