import { cn } from "@/lib/tw-utils"

export type SeperatorProps = {
  className?: string
  label?: string
  labelClassName?: string
}

export function Seperator(props: SeperatorProps) {
  return (
    <div className={cn("relative my-2 font-medium", props.className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-200" />
      </div>
      <div className="relative flex justify-center text-sm leading-6">
        {props.label && (
          <span className={cn("bg-background text-primary px-6 text-center", props.labelClassName)}>
            {props.label}
          </span>
        )}
        {!props.label && <span className="text-primary px-6 opacity-0">foo</span>}
      </div>
    </div>
  )
}
