import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { DataTableV2 } from "@/components/datatable-v2"
import { DocumentThumbnailViewer } from "@/components/document-thumbnail-viewer"
import { InputField } from "@/components/hook-form/input-field"
import { SelectField } from "@/components/hook-form/select-field"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog"
import { Form, FormField } from "@/components/ui/form"
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"
import StatusBall from "@/components/ui/status-ball"
import { TimeAgo } from "@/components/ui/time-ago"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { QueryKeys } from "@/lib/query-keys"
import { RouterOutput, trpc } from "@/lib/trpc-v2"
import { cn } from "@/lib/tw-utils"
import { supported_oems } from "@ai/core/oems"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ColumnDef } from "@tanstack/react-table"
import { EyeIcon, PauseIcon, PlayIcon, TrashIcon } from "lucide-react"
import React from "react"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { z } from "zod"

export async function loader() {
  authenticateGuard(["admin"])
}

type Record = RouterOutput["precisionfetch"]["listBacklog"]["items"][number]

const formSchema = z.object({
  oem: z.enum(supported_oems, { required_error: "OEM is required" }),
  searchTerm: z.string().min(1, { message: "Search term is required" }),
})

function AddBacklogDialog() {
  const [open, setOpen] = React.useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      oem: undefined,
      searchTerm: "",
    },
  })

  const queryClient = useQueryClient()

  const addMutation = useMutation({
    mutationFn: (values: z.infer<typeof formSchema>) =>
      trpc.precisionfetch.addToBacklog.mutate(values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QueryKeys.precisionFetch.backlogs() })
      setOpen(false)
      form.reset()
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    addMutation.mutate(values)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="default" size="sm">
          Add to Backlog
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add to Backlog</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="grid gap-4">
            <FormField
              control={form.control}
              name="oem"
              render={({ field }) => (
                <SelectField
                  field={field}
                  label="OEM *"
                  options={supported_oems.map((oem) => ({ label: oem, value: oem }))}
                />
              )}
            />
            <FormField
              control={form.control}
              name="searchTerm"
              render={({ field }) => (
                <InputField
                  field={field}
                  label="Search Term *"
                  description="e.g. Part number, model, or system type"
                />
              )}
            />
            <Button type="submit" className="w-full" disabled={addMutation.isPending}>
              Add
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

function statusText(status: Record["status"]) {
  switch (status) {
    case "paused":
      return "Paused"
    case "pending":
      return "Pending"
    case "downloading":
      return "Downloading"
    case "downloaded":
      return "Downloaded"
    case "verifying":
      return "Verifying"
    case "converting":
      return "Converting"
    case "converted":
      return "Converted"
    case "finalizing":
      return "Finalizing"
    case "successful":
      return "Successful"
    case "duplicate":
      return "Duplicate"
    case "error":
      return "Failed"
    case "invalid":
      return "Rejected"
  }
}

const PAGE_SIZE = 15

export function Page() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [paging, setPaging] = React.useState<{
    after?: string | null
    before?: string | null
    limit: number
  }>({
    after: null,
    before: null,
    limit: PAGE_SIZE,
  })

  const { isPending, data } = useQuery({
    queryKey: QueryKeys.precisionFetch.backlogs(paging),
    queryFn: () => trpc.precisionfetch.listBacklog.query(paging),
    refetchInterval: 1000 * 10,
  })

  const deleteMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => trpc.precisionfetch.deleteBacklog.mutate({ id }),
    onSuccess: async (_data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogs(),
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogItem(variables.id),
        }),
      ])
    },
  })

  const retryMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => trpc.precisionfetch.retryBacklog.mutate({ id }),
    onSuccess: async (_data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogs(),
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogItem(variables.id),
        }),
      ])
    },
  })

  const stopMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => trpc.precisionfetch.stopBacklog.mutate({ id }),
    onSuccess: async (_data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogs(),
        }),
        queryClient.invalidateQueries({
          queryKey: QueryKeys.precisionFetch.backlogItem(variables.id),
        }),
      ])
    },
  })

  const columns: ColumnDef<Record>[] = [
    {
      id: "status",
      header: "Status",
      cell: ({ row }) => {
        const backlog = row.original
        return (
          <div
            className={cn("flex flex-row items-center gap-x-2 text-sm", {
              "animate-pulse":
                backlog.status === "downloading" ||
                backlog.status === "downloaded" ||
                backlog.status === "verifying" ||
                backlog.status === "converting" ||
                backlog.status === "converted" ||
                backlog.status === "finalizing",
            })}
          >
            <StatusBall status={backlog.status} /> {statusText(backlog.status)}
          </div>
        )
      },
    },
    {
      id: "image",
      header: "",
      cell: ({ row }) => {
        const record = row.original as Record
        return <DocumentThumbnailViewer {...record} />
      },
    },
    {
      header: "OEM",
      accessorKey: "oem",
    },
    {
      header: "Domain",
      accessorKey: "domain",
    },
    {
      header: "File",
      accessorKey: "filenameInUrl",
    },
    {
      header: "Created",
      accessorKey: "timeCreated",
      cell: ({ row }) => {
        const record = row.original as Record
        return <TimeAgo date={record.timeCreated} />
      },
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        const backlog = row.original as Record
        return (
          <div className="flex flex-row justify-end gap-2">
            {backlog.status === "downloading" ||
            backlog.status === "downloaded" ||
            backlog.status === "verifying" ||
            backlog.status === "converting" ||
            backlog.status === "converted" ||
            backlog.status === "finalizing" ? (
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={() => {
                  stopMutation.mutate({ id: backlog.id })
                }}
                //isLoading={retryMutation.isPending}
                disabled={stopMutation.isPending}
              >
                <PauseIcon />
              </Button>
            ) : (
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={() => {
                  retryMutation.mutate({ id: backlog.id })
                }}
                //isLoading={retryMutation.isPending}
                disabled={retryMutation.isPending}
              >
                <PlayIcon />
              </Button>
            )}
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={() => {
                deleteMutation.mutate({ id: backlog.id })
              }}
              //isLoading={deleteMutation.isPending}
              disabled={deleteMutation.isPending}
            >
              <TrashIcon />
            </Button>
            <Button
              type="button"
              variant="ghost"
              size="sm"
              onClick={() => navigate(`/precision-fetch/backlog/${backlog.id.trim()}`)}
            >
              <EyeIcon />
            </Button>
          </div>
        )
      },
    },
  ]

  const pagination = (
    <Pagination>
      <PaginationContent>
        <PaginationItem>
          <PaginationPrevious
            disabled={!data?.hasBefore}
            onClick={() => {
              setPaging((prev) => ({
                ...prev,
                after: null,
                before: data?.before ?? null,
              }))
            }}
          />
        </PaginationItem>
        <PaginationItem />
        <PaginationItem>
          <PaginationNext
            disabled={!data?.hasAfter}
            onClick={() => {
              setPaging((prev) => ({
                ...prev,
                before: null,
                after: data?.after ?? null,
              }))
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Precision Fetch",
            href: "/precision-fetch/library",
          },
          {
            title: "Backlog",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Precision Fetch Backlog</H1>
        <Lead className="mb-8 text-balance">
          Below is the current backlog of documents being processed by Precision Fetch.
        </Lead>
        <div className="mb-4 flex justify-end">
          <AddBacklogDialog />
        </div>
        {pagination}
        <DataTableV2
          columns={columns}
          data={data?.items ?? []}
          skeletonRowsCount={PAGE_SIZE}
          isLoading={isPending || !data}
        />
        {pagination}
      </Main>
    </>
  )
}
