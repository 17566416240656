import { InputField } from "@/components/hook-form/input-field.tsx"
import { TextareaField } from "@/components/hook-form/textarea-field.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import { Form, FormField } from "@/components/ui/form.tsx"
import { RouterOutput } from "@/lib/trpc"
import { ErrorMessage } from "@hookform/error-message"
import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { z } from "zod"
import { AssetsManager } from "./assets-manager.tsx"

type KnowledgeData = NonNullable<RouterOutput["knowledge"]["get"]>

export type KnowledgeFormProps = {
  data?: KnowledgeData
  actions: (state: {
    isSubmitting: boolean
    isLoading: boolean
    isUploading: boolean
  }) => React.ReactNode
  onSubmit: (values: z.infer<typeof formSchema>) => any
}

const formSchema = z.object({
  name: z.string().min(1, {
    message: "Please provide a name.",
  }),
  description: z.string().min(1, {
    message: "Please provide a description.",
  }),
  assets: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      contentType: z.string(),
      contentMd5: z.string(),
      sizeBytes: z.number(),
      assetClass: z.enum(["data", "document", "image", "video", "audio", "unknown"]),
      status: z.enum(["waiting_upload", "uploaded", "ready", "error", "deleted"]),
    }),
  ),
})

export function KnowledgeForm(props: KnowledgeFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: props.data?.name ?? "",
      description: props.data?.description ?? "",
      assets: props.data?.assets ?? [],
    },
  })

  const [isUploading, setIsUploading] = React.useState(false)

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <div className="grid gap-6">
          <Card>
            <CardHeader>
              <CardTitle>Description</CardTitle>
              <CardDescription>Describe the knowledge, and its purpose.</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6">
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="name"
                  disabled={form.formState.isSubmitting || form.formState.isLoading}
                  render={({ field }) => <InputField label="Name" field={field} />}
                />
              </div>
              <div className="grid gap-3">
                <FormField
                  control={form.control}
                  name="description"
                  disabled={form.formState.isSubmitting || form.formState.isLoading}
                  render={({ field }) => <TextareaField label="Description" field={field} />}
                />
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>Files</CardTitle>
              <CardDescription>
                These are files that will be exposed to any ChatBot that is configured to utilize
                this Knowledge.
              </CardDescription>
              <CardDescription>
                Please ensure that you carefully select the files that you wish to make available to
                ChatBots.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Controller
                control={form.control}
                name="assets"
                render={({ field }) => (
                  <>
                    <AssetsManager
                      onChange={field.onChange}
                      value={field.value}
                      onUploadingChange={setIsUploading}
                    />
                    <div className="text-destructive text-sm">
                      <ErrorMessage errors={form.formState.errors} name="assets" />
                    </div>
                  </>
                )}
              />
            </CardContent>
          </Card>

          {props.actions({ ...form.formState, isUploading })}
        </div>
      </form>
    </Form>
  )
}
