import { calculateMd5, hexToBase64 } from "@/lib/files"
import { formatSize } from "@/lib/format"
import { trpc } from "@/lib/trpc"
import { cn } from "@/lib/tw-utils"
import { ColumnDef } from "@tanstack/react-table"
import { CheckCircleIcon, CircleAlertIcon, CloudUploadIcon, Trash2Icon } from "lucide-react"
import React from "react"
import { DataTable } from "./datatable.tsx"
import { SimpleFileSelector } from "./simple-file-selector.tsx"
import { Button } from "./ui/button.tsx"

export type Asset = {
  id: string
  contentType: string
  contentMd5: string
  sizeBytes: number
  assetClass: "data" | "document" | "image" | "video" | "audio" | "unknown"
  status: "waiting_upload" | "uploaded" | "ready" | "error" | "deleted"
  name: string
}

export type AssetsManagerProps = {
  onChange: (value: Asset[]) => void
  value: Asset[]
  onUploadingChange: (isUploading: boolean) => void
}

export type BacklogAssetManagerItem = {
  type: "backlog"
  file: File
  contentMd5: string
  status: "pending" | "uploading" | "error"
}

export type UploadedAssetManagerItem = {
  type: "uploaded"
  contentMd5: string
  asset: Asset
  status: "uploaded"
}

export type AssetManagerItem = BacklogAssetManagerItem | UploadedAssetManagerItem

export function AssetsManager(props: AssetsManagerProps) {
  const [items, setItems] = React.useState<AssetManagerItem[]>(
    props.value.map((i) => ({
      type: "uploaded",
      asset: i,
      contentMd5: i.contentMd5,
      status: "uploaded",
    })),
  )

  const columns: ColumnDef<AssetManagerItem>[] = [
    {
      id: "name",
      header: "Name",
      cell: ({ row }) => {
        const record = row.original
        return (
          <span
            className={cn({
              "animate-pulse": record.status === "pending" || record.status === "uploading",
              "text-destructive": record.status === "error",
            })}
          >
            {record.type === "backlog" ? record.file.name : record.asset.name}
          </span>
        )
      },
    },
    {
      id: "contentType",
      header: "Type",
      cell: ({ row }) => {
        const record = row.original
        return (
          <span
            className={cn({
              "animate-pulse": record.status === "pending" || record.status === "uploading",
              "text-destructive": record.status === "error",
            })}
          >
            {record.type === "backlog" ? record.file.type : record.asset.contentType}
          </span>
        )
      },
    },
    {
      id: "sizeBytes",
      header: "Size",
      cell: ({ row }) => {
        const record = row.original
        return (
          <span
            className={cn("whitespace-nowrap", {
              "animate-pulse": record.status === "pending" || record.status === "uploading",
              "text-destructive": record.status === "error",
            })}
          >
            {record.type === "backlog"
              ? formatSize(record.file.size)
              : formatSize(record.asset.sizeBytes)}
          </span>
        )
      },
    },
    {
      id: "asset-class",
      header: "Class",
      cell: ({ row }) => {
        const record = row.original
        if (record.type === "uploaded") {
          return record.asset.assetClass
        }
        return "-"
      },
    },
    {
      id: "status-detail",
      header: "Status",
      cell: ({ row }) => {
        const record = row.original
        if (record.type === "uploaded") {
          return record.asset.status
        }
        return "-"
      },
    },
    {
      id: "status",
      cell: ({ row }) => {
        const record = row.original
        const icon = (function () {
          switch (record.status) {
            case "error":
              return <CircleAlertIcon className="size-3.5" />
            case "uploaded":
              return <CheckCircleIcon className="size-3.5" />
            default:
              return <CloudUploadIcon className="size-3.5" />
          }
        })()
        return (
          <span
            className={cn("whitespace-nowrap", {
              "animate-pulse": record.status === "pending" || record.status === "uploading",
              "text-destructive": record.status === "error",
            })}
          >
            {icon}
          </span>
        )
      },
    },
    {
      id: "action",
      cell: ({ row }) => {
        const record = row.original
        return (
          <Button
            variant="ghost"
            onClick={() => {
              switch (record.status) {
                case "pending":
                case "uploading": {
                  setItems((current) =>
                    current.filter(
                      (i) =>
                        i.type !== "backlog" ||
                        (i.type === "backlog" && i.contentMd5 !== record.contentMd5),
                    ),
                  )
                  break
                }
                case "uploaded": {
                  setItems((current) =>
                    current.filter(
                      (i) =>
                        i.type !== "uploaded" ||
                        (i.type === "uploaded" && i.contentMd5 !== record.contentMd5),
                    ),
                  )
                  props.onChange(props.value.filter((i) => i.contentMd5 !== record.contentMd5))
                  break
                }
              }
            }}
          >
            <Trash2Icon />
          </Button>
        )
      },
    },
  ]

  // Trigger uploads of newly selected files
  React.useEffect(() => {
    const startUpload = async (item: BacklogAssetManagerItem) => {
      try {
        const uploadMeta = await trpc.assets.prepare.mutate({
          filename: item.file.name,
          contentType: item.file.type,
          contentMd5: item.contentMd5,
          sizeBytes: item.file.size,
        })

        const response = await fetch(uploadMeta.uploadUrl, {
          method: "PUT",
          body: item.file,
          headers: {
            "content-md5": hexToBase64(item.contentMd5),
            "content-type": item.file.type.toLowerCase(),
          },
        })

        if (!response.ok) {
          const text = await response.text()
          console.error("Asset upload failed:", text)
          setItems((current) =>
            current.map((i) =>
              i.type === "backlog" && i.contentMd5 === item.contentMd5
                ? { ...i, status: "error" }
                : i,
            ),
          )
        } else {
          const asset = await trpc.assets.uploaded.mutate({ assetId: uploadMeta.assetId })

          setItems((current) =>
            current.map((i) =>
              i.type === "backlog" && i.contentMd5 === item.contentMd5
                ? {
                    type: "uploaded",
                    contentMd5: item.contentMd5,
                    asset,
                    status: "uploaded",
                  }
                : i,
            ),
          )
        }
      } catch (err) {
        setItems((current) =>
          current.map((i) =>
            i.type === "backlog" && i.contentMd5 === item.contentMd5
              ? { ...i, status: "error" }
              : i,
          ),
        )
        console.error("Asset upload failed:", err)
      }
    }

    const toUpload = items.filter(
      (i): i is BacklogAssetManagerItem => i.type === "backlog" && i.status === "pending",
    )

    if (toUpload.length === 0) {
      return
    }

    props.onUploadingChange(true)
    Promise.all(toUpload.map(startUpload)).then(() => props.onUploadingChange(false))

    setItems((current) => {
      return current.map((i) => {
        if (i.type !== "backlog") {
          return i
        }
        return toUpload.some((u) => u.contentMd5 === i.contentMd5)
          ? { ...i, status: "uploading" }
          : i
      })
    })
  }, [items, setItems])

  // Callback newly uploaded files
  React.useEffect(() => {
    const uploaded = items.filter((i): i is UploadedAssetManagerItem => i.type === "uploaded")
    const newItems = uploaded.filter((i) => !props.value.some((v) => v.contentMd5 === i.contentMd5))
    if (newItems.length > 0) {
      props.onChange([...props.value, ...newItems.map((i) => i.asset)])
    }
  }, [props.value, props.onChange, items])

  const onFileSelect = async (files: File[]) => {
    if (files.length > 0) {
      const filesWithHash = await Promise.all(
        files.map(async (file) => ({
          file,
          contentMd5: await calculateMd5(file),
        })),
      )

      const newFiles = filesWithHash.filter(
        ({ file, contentMd5 }) =>
          !items.some((item) =>
            item.type === "backlog"
              ? item.file.name === file.name && item.contentMd5 === contentMd5
              : item.asset.name === file.name && item.asset.contentMd5 === contentMd5,
          ),
      )

      if (newFiles.length > 0) {
        setItems((current) => [
          ...current,
          ...newFiles.map<AssetManagerItem>((f) => ({
            type: "backlog",
            file: f.file,
            contentMd5: f.contentMd5,
            status: "pending",
          })),
        ])
      }
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <DataTable columns={columns} data={items} emptyMessage="No files added" />
      <div>
        <SimpleFileSelector
          fileTypes={[".csv", ".xlsx", ".pdf", ".txt", ".doc", ".docx", ".png", ".jpeg", ".jpg"]}
          multiple
          fileSizeLimitInBytes={1024 * 1024 * 10}
          onFileSelect={onFileSelect}
          variant="button"
        />
      </div>
    </div>
  )
}
