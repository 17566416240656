import { Link } from "react-router"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "./ui/breadcrumb.tsx"
import { Separator } from "./ui/separator.tsx"
import { SidebarTrigger } from "./ui/sidebar.tsx"

export type BreadcrumbItem =
  | {
      title: string
      href?: string
    }
  | {
      element: React.ReactNode
    }

export type BreadcrumbsHeaderProps = {
  className?: string
  items: BreadcrumbItem[]
}

export function BreadcrumbsHeader(props: BreadcrumbsHeaderProps) {
  return (
    <header className="bg-background fixed top-0 z-50 flex h-16 w-full shrink-0 items-center gap-2 border-b px-4">
      <SidebarTrigger className="-ml-1" />
      <Separator orientation="vertical" className="mr-2 h-4" />
      <Breadcrumb>
        <BreadcrumbList>
          {props.items.reduce<React.ReactNode[]>((acc, item, idx) => {
            if (idx !== 0) {
              acc.push(<BreadcrumbSeparator key={`seperator-${idx}`} className="hidden md:block" />)
            }
            if ("element" in item) {
              acc.push(<BreadcrumbItem key={idx}> {item.element}</BreadcrumbItem>)
              return acc
            }
            acc.push(
              <BreadcrumbItem key={item.title}>
                {item.href === undefined ? (
                  <BreadcrumbPage>{item.title}</BreadcrumbPage>
                ) : (
                  <BreadcrumbLink asChild className="hidden md:block">
                    <Link to={item.href}>{item.title}</Link>
                  </BreadcrumbLink>
                )}
              </BreadcrumbItem>,
            )
            return acc
          }, [])}
        </BreadcrumbList>
      </Breadcrumb>
    </header>
  )
}
